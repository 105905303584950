import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import ArticleLayout from "@components/article-layout"
import ArticleLink from '@components/article-link'
import ArticleLinkList from '@components/article-link-list'
import Salutation from "@components/salutation"
import SEO from "@components/seo"
import { urlPathFromRelativeDirectoryAndName } from '@lib/util'

function convertArticleNodeToArticleProp(node) {
  const { frontmatter, fields, parent } = node
  const {
    title,
    subtitle,
    displayDate,
    featuredImage,
  } = frontmatter
  const urlPath = urlPathFromRelativeDirectoryAndName(parent.relativeDirectory, parent.name)
  const readingTimeMinutes = fields.readingTime.minutes

  return {
    urlPath,
    title,
    subtitle,
    displayDate,
    featuredImage,
    readingTimeMinutes,
  };
}

const ArticlesPage = ({
  data,
}) => {
  const featuredArticles = data.featuredArticles.edges.map((edge) => convertArticleNodeToArticleProp(edge.node));
  const articles = data.articles.edges.map((edge) => convertArticleNodeToArticleProp(edge.node));

  return (
    <ArticleLayout>
      <SEO 
        title="Articles"
        description="Dog training guides, tips, and more for the Harper community." 
      />

      <main className='articles-index'>
        
        <div className='container'>
          <div className="articlesListHeader">
            <h1 className="sectionTitle">All Articles</h1>
          </div>
        </div>

        {featuredArticles.length == 1 && (
          <ArticleLink
            isFeatured={true}
            article={featuredArticles[0]}
          />
        )}

        <div className='container'>
          <div className="articlesListHeader">
            <h2 className="listTitle">The Latest</h2>
          </div>
        </div>
        
        <div className="articlesWrap">
          <ArticleLinkList
            articles={articles}
          />
        </div>
      </main>
      <Salutation />
    </ArticleLayout>
  );
};

export default ArticlesPage;

ArticlesPage.propTypes = {
  data: PropTypes.shape({
    articles: PropTypes.arrayOf(PropTypes.object),
    featuredArticles: PropTypes.arrayOf(PropTypes.object),
  }),
};

export const pageQuery = graphql`
  query {
    articles:allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "articles/.*[.]md$/"},
        frontmatter: {
          topics: {nin: ["Course Intro","Tips"]},
          hideFromIndex: {ne: true},
          isFeaturedOnRoot: {ne: true}
        },
      },
      sort: {
        fields: frontmatter___date, 
        order: DESC,
      },
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            topics
            displayDate: date(formatString: "MMM D, YYYY")
            featuredImage {
              childImageSharp {
                thumbnailImage: gatsbyImageData(width: 300)
              }
            }
          }
          fields {
            readingTime {
              minutes
            }
          }
          parent {
            ... on File {
              name
              relativeDirectory
            }
          }
        }
      }
    }
    featuredArticles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "articles/.*[.]md$/"},
        frontmatter: {
          isFeaturedOnRoot: {eq: true}
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            displayDate: date(formatString: "MMM D, YYYY")
            featuredImage {
              childImageSharp {
                headlineImage: gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          fields {
            readingTime {
              minutes
            }
          }
          parent {
            ... on File {
              name
              relativeDirectory
            }
          }
        }
      }
    }
  }
`;
